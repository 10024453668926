"use client";

// Core
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

// Components
import { Layout } from "@/client/components/ui/Layout";
import { LayoutContainer } from "@/client/components/layouts/layout-container";
import { Row } from "@/client/components/ui/Row";
import { Col } from "@/client/components/ui/Col";
import { Image } from "@/client/components/ui/Image";
import { Text } from "@/client/components/ui/Typography/Text";
import { BorderDecor } from "@/client/components/ui/BorderDecor";
import { Logo } from "@/client/components/common/logo";
import { Button } from "@/client/components/ui/Button";

// Hooks
import { useConsentCookiesReset } from "@/client/domains/user/consent-cookies/hooks/use-consent-cookies-reset";

// Utils
import st from "./styles.module.css";
import { ROW_GUTTER_TYPE } from "@/client/components/ui/Row/utils";
import { SCROLL_NAMES } from "@/client/utils/scroll-to";

type LayoutAppFooter4ColsPropsType = {
  left?: ReactNode | ReactNode[];
  center?: ReactNode | ReactNode[];
  right?: ReactNode | ReactNode[];
};

export const LayoutAppFooter4Cols = ({ left, center, right }: LayoutAppFooter4ColsPropsType) => {
  const { t } = useTranslation("common");
  const { resetCookie } = useConsentCookiesReset();

  return (
    <Layout.Footer className={st["layout-app-footer"]} data-testid="layout-app-footer">
      <Element name={SCROLL_NAMES.footer} />
      <LayoutContainer>
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
          <Col span={6} data-testid="footer-logo">
            <Logo alt={t("logoAlt")} />
            <Button
              size="large"
              btnType="transparent"
              onClick={resetCookie}
              className={st["footer__cookie-btn"]}
            >
              {t("cookies:resetBtn")}
            </Button>
          </Col>
          <Col span={6} data-testid="footer-left-content">
            {left}
          </Col>
          <Col span={6} data-testid="footer-center-content">
            {center}
          </Col>
          <Col span={6} data-testid="footer-right-content">
            {right}
          </Col>
        </Row>
        <BorderDecor color="gray-9" gutter="16" position="vertical" gutterPosition="vertical">
          <Row
            gutter={ROW_GUTTER_TYPE.md.horizontal}
            align="middle"
            data-testid="footer-copyright-violation-warning"
          >
            <Col span={3}>
              <Image
                src={"/assets/images/tech-doc-logo.svg"}
                alt={"tech doc logo"}
                width={120}
                height={34}
                unoptimized
              />
            </Col>
            <Col span={21}>
              <Text size="12" color="gray-700" tag="p">
                {t("footer.copyright")}
              </Text>
            </Col>
          </Row>
        </BorderDecor>
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
          <Col span={24} className={st.footer__date} data-testid="footer-date">
            <Text size="12" color="gray-700">
              {t("footer.date", { year: new Date().getFullYear() })}
            </Text>
          </Col>
        </Row>
      </LayoutContainer>
    </Layout.Footer>
  );
};
