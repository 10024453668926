export const emails = Object.freeze({
  helpService: "help.trader@autodoc.pro",
  salesManager: "sales.trader@autodoc.pro",
} as const);

export const phoneNumbers = Object.freeze({
  helpService: "+49 30 22027278",
} as const);

export const linksExternal = Object.freeze({
  codeOfConduct: "https://scdn.autodoc.pro/code_of_conduct/en/Code%20of%20Conduct.pdf",
  aboutUs: "https://autodoc.group/en/about-us",
});
