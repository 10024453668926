"use client";
// Core
import { useTranslation } from "react-i18next";

// Components
import { Row } from "@/client/components/ui/Row";
import { Col } from "@/client/components/ui/Col";
import { Button } from "@/client/components/ui/Button";

// Utils
import { ROW_GUTTER_TYPE } from "@/client/components/ui/Row/utils";
import st from "./styles.module.css";

export type ConsentCookiesActionsProps = {
  testId?: string;
  onReject: () => void;
  onAllowAll: () => void;
  onSetSettings: () => void;
};

export const ConsentCookiesActions = (props: ConsentCookiesActionsProps) => {
  const { onReject, onAllowAll, onSetSettings, testId = "consent-cookies" } = props;

  const { t } = useTranslation(["cookies"]);

  return (
    <Row
      gutter={ROW_GUTTER_TYPE.md.horizontal}
      justify="end"
      className={st["consent-cookies-actions"]}
    >
      <Col xs={24} md={7} lg={5} className={st["consent-cookies-actions__button"]}>
        <Button
          size="large"
          onClick={onReject}
          data-testid={`${testId}-btn-reject`}
          block
          data-kmtx-cookies-refuse
        >
          {t("consentCookies.buttons.rejectCookies")}
        </Button>
      </Col>
      <Col xs={24} md={7} lg={5} className={st["consent-cookies-actions__button"]}>
        <Button
          size="large"
          data-testid={`${testId}-btn-preferences`}
          onClick={onSetSettings}
          block
          data-kmtx-cookies-customize
        >
          {t("consentCookies.buttons.managePreferences")}
        </Button>
      </Col>
      <Col xs={24} md={7} lg={5} className={st["consent-cookies-actions__button"]}>
        <Button
          size="large"
          type="primary"
          data-testid={`${testId}-btn-allow-all`}
          onClick={onAllowAll}
          block
          data-allow-all-cookies
          data-kmtx-cookies-allow
          data-kmtx-cookies-screen-type="general"
        >
          {t("consentCookies.buttons.allowCookies")}
        </Button>
      </Col>
    </Row>
  );
};
