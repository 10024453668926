import(/* webpackMode: "eager", webpackExports: ["Layout"] */ "__barrel_optimize__?names=Layout!=!/builds/pro/wholesalers-frontend/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/src/client/components/common/client-init/cleint-init.tsx");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/src/client/components/layouts/layout-app/styles.module.css");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/src/client/components/page-elements/footer-private/footer-private.tsx");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/src/client/components/page-elements/footer-public/footer-public.tsx");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/src/client/components/page-elements/header-private/header-basket.tsx");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/src/client/components/page-elements/header-private/header-shop.tsx");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/src/client/components/page-elements/header-public/header-public.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithUser"] */ "/builds/pro/wholesalers-frontend/src/client/components/page-elements/private-page-store/with-user.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationsProvider"] */ "/builds/pro/wholesalers-frontend/src/client/contexts/translations-provider.tsx");
