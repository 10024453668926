// Core
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

// Components
import { Trans } from "react-i18next";
import { Row } from "@/client/components/ui/Row";
import { Col } from "@/client/components/ui/Col";
import { Title } from "@/client/components/ui/Typography/Title";
import { Text } from "@/client/components/ui/Typography/Text";
import { Link } from "@/client/components/common/link";
import { LayoutContainer } from "@/client/components/layouts/layout-container";
import { ConsentCookiesForm } from "../consent-cookies-form";
import { ConsentCookiesActions } from "../consent-cookies-actions";

// Hooks
import { useConsentCookies } from "../../hooks/use-consent-cookies";
import { useStore } from "@/client/hooks/use-store";

// Utils
import { ROW_GUTTER_TYPE } from "@/client/components/ui/Row/utils";
import { routes } from "@/client/definitions/book";
import st from "./styles.module.css";

export type ConsentCookiesProps = {
  testId?: string;
};

export const ConsentCookies = observer((props: ConsentCookiesProps) => {
  const { testId = "consent-cookies" } = props;

  const { t } = useTranslation(["cookies"]);

  const {
    consentCookies: {
      individualSettings,
      isCookieDetails,
      isOpenConsentCookies,
      setIndividualSettings,
      setIsCookieDetails,
    },
  } = useStore();
  const { onAllowAllCookies, onRejectAllCookies, setConsentCookies } = useConsentCookies();

  const handleSetSettings = () => {
    setIsCookieDetails(true);
    setIndividualSettings(true);
  };

  return isOpenConsentCookies ? (
    <div className={st["consent-cookies"]} data-testid={testId}>
      <LayoutContainer>
        {!individualSettings ? (
          <>
            <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
              <Col span={24}>
                <Title tag="h4" size="16" color="dark" className={st["consent-cookies__title"]}>
                  {t("consentCookies.title")}
                </Title>
                <Text tag="p" color="dark">
                  <Trans
                    i18nKey="cookies:consentCookies.description"
                    components={[<Link key={routes.privacyPolicy} href={routes.privacyPolicy} />]}
                  />
                </Text>
              </Col>
            </Row>
            <ConsentCookiesActions
              testId={testId}
              onReject={onRejectAllCookies}
              onAllowAll={onAllowAllCookies}
              onSetSettings={handleSetSettings}
            />
          </>
        ) : (
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
            <Col span={24}>
              <Title tag="h4" size="16" color="dark" className={st["consent-cookies__title"]}>
                {t("consentCookies.individualTitle")}
              </Title>
              <ConsentCookiesForm
                onSubmit={setConsentCookies}
                onAllowAllCookies={onAllowAllCookies}
                testId={`${testId}-form`}
                hasDescription={isCookieDetails}
              />
            </Col>
          </Row>
        )}
      </LayoutContainer>
    </div>
  ) : null;
});
