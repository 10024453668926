import { routes } from "@/client/definitions/book";
import { linksExternal } from "@/client/definitions/contacts";

export type MenuNavItemData = {
  id?: string;
  href: string;
  anchor?: string;
  icon?: string;
  asBlankLink?: boolean;
};

export type MenuData = {
  heading: string;
  items: MenuNavItemData[];
};

export const aboutBlockDataModel = {
  heading: "footer.navigation.about.heading",
  items: [
    {
      anchor: "footer.navigation.about.links.0",
      href: routes.companyInfo,
    },
    {
      anchor: "footer.navigation.about.links.1",
      href: linksExternal.aboutUs,
      asBlankLink: true,
    },
    {
      anchor: "footer.navigation.about.links.2",
      href: routes.faq,
    },
  ],
};

export const supportBlockDataModel = {
  heading: "footer.navigation.support.heading",
  items: [
    {
      anchor: "footer.navigation.support.links.0",
      href: "/terms-conditions",
    },
    {
      anchor: "footer.navigation.support.links.1",
      href: routes.privacyPolicy,
    },
    {
      anchor: "footer.navigation.support.links.2",
      href: linksExternal.codeOfConduct,
      asBlankLink: true,
    },
  ],
};

export const customerServiceDataModel = {
  heading: "footer.navigation.customerService.heading",
  items: [
    {
      anchor: "footer.navigation.customerService.links.0",
      href: routes.payment,
    },
    {
      anchor: "footer.navigation.customerService.links.1",
      href: routes.delivery,
    },
    {
      anchor: "footer.navigation.customerService.links.2",
      href: routes.returnsRefunds,
    },
  ],
};
